import { template as template_b11ad83f5b7648feb7e9cfd98715c4dd } from "@ember/template-compiler";
const FKControlMenuContainer = template_b11ad83f5b7648feb7e9cfd98715c4dd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
