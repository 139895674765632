import { template as template_440dc64d0bd34a4d9a845f12b85ac0bd } from "@ember/template-compiler";
const FKLabel = template_440dc64d0bd34a4d9a845f12b85ac0bd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
