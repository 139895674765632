import { template as template_378b5d8c92754ae78c3d2dfd734dc55d } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_378b5d8c92754ae78c3d2dfd734dc55d(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
